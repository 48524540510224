'use strict';

window.site = window.site || {};

/**
 * Init Mobile Menu related scripts
 * @class Site
 * @static
 */
site.Slider007 = function Slider007() {
  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    var $sliders = [].slice.call(document.querySelectorAll('.slider007'));

    if ($sliders.length) {
      setTimeout(function () {
        $sliders.forEach(function ($slider) {
          var $sliderSection = $slider.querySelector('.slider-wrapper');

          var flkty = new Flickity($sliderSection, {
            resize: false,
            cellAlign: 'center',
            cellSelector: '.slider-slide',
            contain: true,
            imagesLoaded: true,
            adaptiveHeight: true,
            groupCells: true,
            lazyLoad: true,
            wrapAround: true,
            pageDots: true,
            prevNextButtons: true,
            autoPlay: false
          });
          // flkty.on('staticClick', (event, pointer, cellElement, cellIndex) => {
          //   if (typeof cellIndex === 'number') {
          //     flkty.selectCell(cellIndex);
          //   }
          // });
        });
      }, 200);
    }

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();