'use strict';

// Globals and dependencies
/* global Vue */
/* global loadjs */
/* global Tooltip */

loadjs.ready(['vue', 'axios'], {
  success: function success() {
    if (!document.querySelector('#tooltip-scope')) {
      return false;
    }
    // Define a new component called accordion-item
    Vue.component('tooltip', {
      props: {
        name: {},
        placement: { default: 'top' },
        offset: { default: false }
      },
      mounted: function mounted() {
        var _this = this;

        document.querySelectorAll('[data-tooltip-name="' + this.name + '"]').forEach(function (elem) {
          /* eslint no-new: 0 */
          new Tooltip(elem, {
            placement: _this.placement,
            title: _this.$el.innerHTML,
            offset: _this.offset,
            html: true
          });
        });
      },
      template: '\n        <div v-show="false">\n          <slot></slot>\n        </div>\n      '
    });

    var init = new Vue({ el: '#tooltip-scope' });

    return init;
  }
});