'use strict';

window.site = window.site || {};

/**
 * Utilities for Google Maps.
 * @class Map
 * @static
 */
site.MultiSelect = function MultiSelect() {

  'use strict';

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  /**
   * jQuery elements.
   */

  /**
   * Initialize Google Maps instances.
   * @public
   */

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    // Every main site form should be Native Multiselect on Mobile + Stop script execution
    if (window.site.App.config('device') === 'mobile' && document.querySelector('.main .wf-group.custom-multiple')) {
      document.querySelector('.main .wf-group.custom-multiple').style.display = 'none';
      document.querySelector('.main .wf-group.custom-multiple').setAttribute('name', '');
      document.querySelector('.main .wf-group.custom-multiple-mobile select').setAttribute('multiple', 'multiple');
      document.querySelector('.main .wf-group.custom-multiple-mobile select').setAttribute('required', 'required');
      document.querySelector('.main .wf-group.custom-multiple-mobile select').style.minHeight = '61px';
      return false;
    } else if (window.site.App.config('device') !== 'mobile' && document.querySelector('.main .wf-group.custom-multiple')) {
      document.querySelector('.main .wf-group.custom-multiple').setAttribute('required', 'required');
      document.querySelector('.main .wf-group.custom-multiple-mobile').style.display = 'none';
      document.querySelector('.main .wf-group.custom-multiple-mobile select').setAttribute('name', '');
    }

    var newParentWrapperEl = document.createElement('div');
    newParentWrapperEl.classList.add('wf-group-checkboxes-wrapper');
    var oldParentEl = document.querySelector('.custom-multiple');

    if (oldParentEl) {
      var _newParentWrapperEl = document.createElement('div');
      _newParentWrapperEl.classList.add('wf-group-checkboxes-wrapper');

      var newParentEl = document.createElement('div');
      newParentEl.classList.add('wf-group-checkboxes');

      var textSelector = document.createElement('div');
      textSelector.classList.add('wf-group-checkboxes-text');
      textSelector.textContent = 'Selectionner des options';

      var tagsWrapper = document.createElement('div');
      tagsWrapper.classList.add('wf-tags-wrapper');

      oldParentEl.appendChild(tagsWrapper);
      oldParentEl.appendChild(_newParentWrapperEl);

      _newParentWrapperEl.appendChild(textSelector);
      _newParentWrapperEl.appendChild(newParentEl);

      var childrenEls = oldParentEl.querySelectorAll('.wf-checkbox');

      childrenEls.forEach(function (element) {
        newParentEl.appendChild(element);

        var currentInput = element.querySelector('input');

        currentInput.addEventListener('change', function () {
          if (currentInput.checked) {
            var newTag = document.createElement('span');
            newTag.setAttribute('data-value', currentInput.value);
            newTag.classList.add('wf-tag');
            newTag.textContent = currentInput.value;
            tagsWrapper.appendChild(newTag);
            element.classList.add('is-active');
          } else {
            var oldTag = document.querySelector('.wf-tag[data-value="' + currentInput.value + '"]');
            oldTag.remove();
            element.classList.remove('is-active');
          }
        });
      });

      textSelector.addEventListener('click', function () {
        newParentEl.classList.toggle('is-active');
      });
    }

    // Return success
    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();