'use strict';

// Globals and dependencies
/* global Vue */
/* global loadjs */

loadjs.ready(['vue'], {
  success: function success() {
    if (!document.querySelector('.problematic-scope')) {
      return false;
    }
    // Define a new component called step-by-step
    Vue.component('problematic-menu', {
      props: {
        items: {
          required: true
        },
        desc: {
          required: true
        }
      },

      data: function data() {
        return {
          selectedItem: 0
        };
      },

      created: function created() {
        setTimeout(function () {
          AOS.init();
        }, 255);
      },

      mounted: function mounted() {
        this.adjustHeight();
      },

      methods: {
        selectItem: function selectItem(item) {
          this.selectedItem = item;

          this.adjustHeight();

          var $scrollToTarget = document.querySelector('.problematic-details');

          if ($scrollToTarget) {
            console.log("scrollToTarget", $scrollToTarget);
            $scrollToTarget.scrollIntoView({ behavior: 'smooth' });
          }
        },
        adjustHeight: function adjustHeight() {
          setTimeout(function () {
            var $container = document.querySelector('.problematic-container');
            var $item = document.querySelector('.problematic-details');

            console.log("Here ---> ", $container, $item, $item.offsetHeight, $container.offsetHeight);

            var newHeight = $container.offsetHeight;

            if ($item.offsetHeight >= document.querySelector('.problematic-description').offsetHeight + document.querySelector('.problematic-list').offsetHeight) {
              newHeight = $item.offsetHeight + 200;
              $container.setAttribute('style', 'height: ' + newHeight + 'px;');

              console.log("newHeight ---> ", newHeight);
            } else {
              $container.removeAttribute("style");
            }
          }, 650);
        }
      },

      watch: {
        // ...
      },

      template: '\n        <div class="problematic-container site-max-width">\n\n          <div class="problematic-container-left" data-aos="fade-up">\n            <div class="problematic-description regular-text" v-html="desc">\n            </div>\n\n            <ul class="problematic-list">\n              <li\n                class="problematic-list-items small-title"\n                v-for="(item, index) in items"\n                v-text="item.title"\n                :class="index == selectedItem ? \'is-active\' : \'\'"\n                @click="selectItem(index)"></li>\n            </ul>\n          </div>\n\n          <div class="problematic-container-right" data-aos="fade-up" data-aos-delay="300">\n            <transition name="fade-box" v-for="(item, index) in items">\n              <div class="problematic-details" v-if="index == selectedItem">\n                <div class="problematic-header small-title">\n                  <img :src="item.image" :alt="item.title" loading="lazy">\n                </div>\n                <div class="problematic-infos">\n                  <h3 class="problematic-heading small-title" v-text="item.title"></h3>\n                  <div class="problematic-text regular-text" v-html="item.text"></div>\n                  <a :href="item.url" class="site-btn is-alternate problematic-btn">En savoir plus</a>\n                </div>\n              </div>\n            </transition>\n          </div>\n\n        </div>\n      '
    });

    var init = new Vue({ el: '.problematic-scope' });

    return init;
  }
});