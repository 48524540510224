'use strict';

// Globals and dependencies
/* global Vue */
/* global loadjs */

loadjs.ready(['vue'], {
  success: function success() {
    if (!document.querySelector('.team-vue-scope')) {
      return false;
    }
    // Define a new component called accordion-item
    Vue.component('inline-expender', {
      props: ['title', 'desc', 'isOpen'],

      data: function data() {
        return {
          isExpended: false,
          target: null
        };
      },

      created: function created() {
        setTimeout(function () {
          AOS.init();
        }, 255);
      },

      mounted: function mounted() {
        setTimeout(function () {
          document.querySelectorAll('.team-entry').forEach(function (item) {
            item.setAttribute('style', 'height: ' + item.offsetHeight + 'px;');
            item.setAttribute('data-original-height', item.offsetHeight);
          });
        }, 1375);
      },

      methods: {
        toggle: function toggle() {
          // console.log(this.isExpended);

          this.$emit('toggled', this.$vnode.key);

          console.log('Child Toggle', this.$vnode.key);

          // if (this.isOpen) {
          //   this.isExpended = false;
          // }else {
          //   this.isExpended = !this.isExpended;
          // }

          // this.isExpended = !this.isExpended;

          // let $target = event.target;
          // if ($target.tagName !== 'A') {
          //   $target = $target.closest('.team-entry');
          // }

          // this.target = $target;
        }
      },

      template: '\n        <a @click.prevent.stop.capture="toggle" :class="{ \'is-expended\': isOpen }">\n          <slot></slot>\n\n          <transition name="fade-delay" >\n            <div class="entry-description-container" v-show="isOpen">\n              <div class="team-entry-infos">\n                <div class="team-entry-description" v-html="desc">\n                </div>\n              </div>\n            </div>\n          </transition>\n        </a>\n      '
    });

    Vue.component('inline-expender-container', {
      props: {
        items: {
          required: true
        },
        identifier: {
          required: true
        }
      },

      data: function data() {
        return { activeItemIndex: null };
      },

      mounted: function mounted() {
        // for(let i = 0; i < this.$children.length; i++) {
        //   console.log(this.$children[i].isExpended);
        // }
      },

      watch: {
        activeItemIndex: function activeItemIndex(_activeItemIndex) {
          var _this = this;

          console.log('Now Watching activeIndex', _activeItemIndex);

          setTimeout(function () {

            var $parent = document.querySelector('.team-entry-container.' + _this.identifier);

            // Always close everything
            $parent.querySelectorAll('.team-entry').forEach(function (elem) {
              elem.style.height = $parent.querySelector('.team-entry').getAttribute('data-original-height') + 'px';
            });

            if (_activeItemIndex != null) {
              console.log('Yay');
              var $target = $parent.querySelector('.team-entry:nth-child(' + (_activeItemIndex + 1) + ')');
              var $expendedItem = $target;
              var $expenderBlock = $target.querySelector('.entry-description-container');
              $expendedItem.style.height = $expendedItem.offsetHeight + $expenderBlock.offsetHeight + 'px';
            }
          }, 355);
        }
      },

      methods: {
        toggled: function toggled(index) {
          console.log('Parent got toggled', index);

          if (this.activeItemIndex === index) {
            this.activeItemIndex = null;
            return;
          }
          // //
          this.activeItemIndex = index;
          console.log(this.activeItemIndex);
        }
      },

      template: '\n        <div class="team-entry-container" :class="items.length >= 3 ? \'col-3\' : \'\'" data-aos="fade-up">\n          <inline-expender\n            class="team-entry"\n            v-for="(item, index) in items"\n            :key="index"\n            :title="item.jobTitle"\n            :desc="item.bio"\n            :isOpen="activeItemIndex == index"\n            @toggled="toggled"\n            >\n              <img :src="item.img" :alt="item.name" data-aos="fade-up" :data-aos-delay="index * 100" loading="lazy">\n              <div class="team-infos-wrapper" data-aos="fade-up" :data-aos-delay="index * 200">\n                <div class="team-infos">\n                  <h3 class="team-name medium-title" v-text="item.name"></h3>\n                  <h2 class="team-job-title" v-text="item.jobTitle"></h2>\n                </div>\n\n                <div class="team-arrow" :class="activeItemIndex == index ? \'is-active\' : \'\'">\n                  <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">\n                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.6 -5.09823e-08L12 1.32432L6 7L-6.94852e-08 1.32432L1.4 -3.86009e-07L6 4.35135L10.6 -5.09823e-08Z" fill="#004367"/>\n                  </svg>\n                </div>\n              </div>\n          </inline-expender>\n\n          <div class="team-divider"></div>\n        </div>\n      '

    });

    var init = new Vue({ el: '.team-vue-scope' });

    return init;
  }
});