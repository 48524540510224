'use strict';

// Globals and dependencies
/* global Vue */
/* global loadjs */

loadjs.ready(['vue', 'axios'], {
  success: function success() {
    if (!document.querySelector('#custom-modal-scope')) {
      return false;
    }
    // Define a new component called count
    Vue.component('custom-modal', {
      props: ['name'],

      data: function data() {
        return {};
      },

      mounted: function mounted() {},

      methods: {
        closeModal: function closeModal() {}
      },
      template: '\n        <div :id="name" class="modal-overlay">\n          <a href="#" class="modal-cancel"></a>\n\n          <div class="modal-box">\n            <slot></slot>\n\n            <footer>\n              <slot name="footer"></slot>\n            </footer>\n\n            <a href="#" class="modal-close">&times;</a>\n          </div>\n        </div>\n      '
    });

    var init = new Vue({ el: '#custom-modal-scope' });

    return init;
  }
});