'use strict';

// Globals and dependencies
/* global Vue */
/* global loadjs */
/* global inViewport */

loadjs.ready(['vue', 'axios'], {
  success: function success() {
    if (!document.querySelector('#conditional-element-scope')) {
      return false;
    }
    // Define a new component called ConditionalElement
    Vue.component('conditional-element', {
      props: ['when-is-hidden', 'and'],

      data: function data() {
        return {
          isVisible: false
        };
      },

      mounted: function mounted() {
        console.log('mounted');
        window.addEventListener('scroll', this.checkDisplay, { passive: true });
      },

      methods: {
        checkDisplay: function checkDisplay() {
          this.isVisible = !inViewport(document.querySelector(this.whenIsHidden)) && !inViewport(document.querySelector(this.and));
        },
        willOpen: function willOpen() {
          console.log('willOpen()');
          setTimeout(function () {
            // Make sure the Popup Contact form always uses Native Multi-select
            document.querySelector('[data-modal="contact-support-modal"] .wf-group.custom-multiple').style.display = 'none';
            document.querySelector('[data-modal="contact-support-modal"] .wf-group.custom-multiple').setAttribute('name', '');
            document.querySelector('[data-modal="contact-support-modal"] .wf-group.custom-multiple-mobile select').setAttribute('multiple', 'multiple');
            document.querySelector('[data-modal="contact-support-modal"] .wf-group.custom-multiple-mobile select').setAttribute('required', 'required');
            document.querySelector('[data-modal="contact-support-modal"] .wf-group.custom-multiple-mobile select').setAttribute('name', 'problematics[]');
            document.querySelector('[data-modal="contact-support-modal"] .wf-group.custom-multiple-mobile select').style.minHeight = '61px';
          }, 555);
        }
      },
      template: '\n        <transition name="fade">\n          <div @click="willOpen">\n            <slot></slot>\n          </div>\n        </transition>\n      '
    });

    // Use Modal Components
    Vue.use(window['vue-js-modal'].default);

    // Define a new component called support-button
    Vue.component('support', {
      data: function data() {
        return {
          message: {},
          errors: {},
          error: '',
          response: null,
          submitted: false,
          isReady: false
        };
      },
      mounted: function mounted() {
        console.log('mounted');
        var $action = document.querySelector('input[name="action"]');

        if ($action) {
          $action.remove();
        }

        setTimeout(function () {

          var submitBtn = document.querySelector('input[type="submit"]');

          if (submitBtn) {
            submitBtn.removeAttribute('disabled');
          }

          var $form = document.querySelector('[data-protect-me-from-spam]');
          if ($form) {
            var $honey = $form.querySelector('#address1');

            $honey.value = ''; // Clear HoneyPot

            var $routing = document.createElement('input');
            $routing.setAttribute('type', 'hidden');
            $routing.setAttribute('name', 'action');
            $routing.setAttribute('value', '/wheelform/message/send');
            $form.appendChild($routing);
          }

          this.isReady = true;
        }.bind(undefined), 5500);
      },
      methods: {
        contactSupport: function contactSupport(e) {
          e.preventDefault();
          console.log(e.target);

          if (document.querySelector('#wf-name-1').value !== '') {
            this.$modal.hide('contact-support-modal');

            Swal.fire({
              type: 'success',
              title: 'Succès !',
              text: 'Merci, votre demande a été envoyé avec succès. Nous vous contacterons d\'ici le prochain jour ouvrable.',
              footer: ''
            });

            // setTimeout(() => {
            e.target.submit();
            // }, 500);

            this.resetForm();
          }
        },

        cancel: function cancel() {
          this.$modal.hide('contact-support-modal');

          this.resetForm();
        },
        checkForm: function checkForm() {
          this.message = {};
          this.submitted = false;
        },
        resetForm: function resetForm() {
          this.message = {};
          this.submitted = false;
        }
      },
      template: '\n        <div>\n          <a\n            @click.prevent="$modal.show(\'contact-support-modal\')"\n            href="#"\n          >\n            <slot name="btn"></slot>\n          </a>\n\n          <modal\n            name="contact-support-modal"\n            width="30%"\n            height="auto"\n            classes="v--modal v--modal-form form001"\n          >\n\n            <section class="modal-form" id="contact-form">\n              <h3 class="form-heading small-title">{{ \'Nous sommes ici pour vous aider.\' }}</h3>\n              <section class="contact-form">\n                <h4 class="form-subHeading small-text">{{ \'Demande d\u2019informations\' }}</h4>\n\n                <form @submit="contactSupport" class="site-form" method="post" accept-charset="UTF-8" enctype="multipart/form-data" data-protect-me-from-spam>\n                  <slot name="form"></slot>\n                </form>\n\n              </section>\n            </section>\n          </modal>\n        </div>\n      '
    });

    var init = new Vue({ el: '#conditional-element-scope' });

    return init;
  }
});

{/* <form @submit="contactSupport" class="site-form" method="post" accept-charset="UTF-8" enctype="multipart/form-data">
                  <slot name="form"></slot>
                </form> */}