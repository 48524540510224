'use strict';

window.site = window.site || {};

/**
 * Init AjaxPagination related scripts
 * @class Site
 * @static
 */
site.popUpCovid = function popUpCovid() {

  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    var $closeButton = document.querySelector('[action-close-box]');
    var $openButton = document.querySelector('[action-open-box]');
    var $popupBox = document.querySelector('[action-popup-box]');
    var popupStorage = window.localStorage.getItem('popupIsHidden');

    if ($popupBox) {
      if (!popupStorage) {
        $popupBox.classList.add('is-visible');
      }

      if ($openButton) {
        $openButton.addEventListener('click', function (e) {
          e.preventDefault();
          $popupBox.classList.add('is-visible');
        });
      }

      if ($closeButton) {
        $closeButton.addEventListener('click', function (e) {
          e.preventDefault();
          $popupBox.classList.remove('is-visible');
          localStorage.setItem('popupIsHidden', 'true');
        });
      }
    }

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();