'use strict';

window.site = window.site || {};

/**
 * Init Mobile Menu related scripts
 * @class Site
 * @static
 */
site.FormSubmit = function FormSubmit() {

  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    var form = document.querySelectorAll('.modal-form');

    if (form) {
      form.addEventListener('submit', function () {
        if (document.querySelector('#wf-name-1').value !== '') {
          Swal.fire({
            type: 'success',
            title: 'Succès !',
            text: 'Merci, votre demande a été envoyé avec succès. Nous vous contacterons d\'ici le prochain jour ouvrable.',
            footer: ''
          });
        }
      });
    }

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();