'use strict';

window.site = window.site || {};

/**
 * Utilities for Google Analytics.
 * @class Track
 * @static
 */
site.Track = function Track() {

  'use strict';

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    var hero = document.querySelector('.site-hero-ab');

    if (hero) {
      sendEvent('heros A/B', 'pageView', 'hero number ' + hero.dataset.pageHero);
    }

    [].slice.call($('[data-track]')).forEach(function (item) {
      // console.log(item);
      item.addEventListener('click', function (e) {
        // console.log(e.target, e.target.dataset.category, e.target.dataset.action, e.target.dataset.label);
        var $item = e.target;

        if (typeof $item.dataset.category === 'string' && typeof $item.dataset.action === 'string' && typeof $item.dataset.label === 'string') {
          sendEvent($item.dataset.category, $item.dataset.action, $item.dataset.label);
        }
      });
    });

    return true;
  };

  /**
   * Send a tracking event.
   * @public
   */
  var sendEvent = function sendEvent(category, action, label) {

    // console.log("ga('send', 'event', category, action, label);");

    // console.log(`
    //   window.dataLayer = window.dataLayer || [];
    //   dataLayer.push({
    //     'event': 'click',
    //     'category': ${category},
    //     'action': ${action},
    //     'label': ${label}
    //   });
    // `);

    if (category && action && label && site.App.config('env') === 'production') {

      window.dataLayer = window.dataLayer || [];
      dataLayer.push({
        'event': 'click',
        'category': category,
        'action': action,
        'label': label
      });

      return true;
    }

    return false;
  };

  /**
   * Send a page view event.
   * @public
   */
  var sendPage = function sendPage(url) {

    var parser;

    if (url === undefined || url === '') {
      url = location.pathname;
    } else {
      parser = document.createElement('a');
      parser.href = url;
      url = parser.pathname + parser.search;
    }

    if (site.App.config('env') === 'production') {
      return ga('send', 'pageview', url);
    }

    return false;
  };

  // Expose public methods & properties
  return {
    init: init,
    page: sendPage,
    event: sendEvent
  };
}();