'use strict';

// Globals and dependencies
/* global Vue */
/* global loadjs */

loadjs.ready(['vue', 'axios'], {
  success: function success() {
    if (!document.querySelector('#dropdown-scope')) {
      return false;
    }
    // Define a new component called count
    Vue.component('dropdown', {
      props: ['position'],

      data: function data() {
        return {
          isOpen: false
        };
      },

      watch: {
        isOpen: function isOpen(_isOpen) {
          if (_isOpen) {
            document.addEventListener('click', this.closeIfClickedOutside);
          }
        }
      },

      mounted: function mounted() {},

      methods: {
        closeIfClickedOutside: function closeIfClickedOutside(event) {
          if (!event.target.closest('.dropdown')) {
            this.isOpen = false;
          }
        }
      },
      template: '\n        <div class="dropdown">\n\n          <div\n            class="dropdown-trigger"\n             @click.prevent="isOpen = ! isOpen"\n             aria-haspopup="true"\n             :aria-expanded="isOpen"\n          >\n            <slot name="trigger"></slot>\n          </div>\n\n          <transition name="pop-out-quick">\n            <div class="dropdown-menu" v-show="isOpen">\n              <slot></slot>\n            </div>\n          </transition>\n\n        </div>\n      '
    });

    var init = new Vue({ el: '#dropdown-scope' });

    return init;
  }
});