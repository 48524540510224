'use strict';

window.site = window.site || {};

/**
 * Init Mobile Menu related scripts
 * @class Site
 * @static
 */
site.GridSlider001 = function GridSlider001() {
  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;
    var $sliders = [].slice.call(document.querySelectorAll('.grid001-slider'));

    if ($sliders.length) {
      setTimeout(function () {
        $sliders.forEach(function ($slider) {
          // const $sliderSection = $slider.querySelector('.slider001-slider');

          var flkty = new Flickity($slider, {
            // options
            resize: true,
            cellAlign: 'center',
            contain: true,
            imagesLoaded: true,
            adaptiveHeight: true,
            // groupCells: true,
            lazyLoad: true,
            wrapAround: false,
            pageDots: false,
            prevNextButtons: false,
            autoPlay: false
          });
        });
      }, 1000);
    }

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();