'use strict';

window.site = window.site || {};

/**
 * Init Mobile Menu related scripts
 * @class Site
 * @static
 */
site.FormBlock = function FormBlock() {

  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    var $form = document.querySelector('.site-form');

    if ($form) {
      var $button = $form.querySelector('input[type=submit]');

      if ($button) {
        $button.addEventListener('click', function (e) {
          var $telInput = $form.querySelector('.wf-field[name=phone]');

          if ($telInput) {
            if ($telInput.value === '+12548593423') {
              e.preventDefault();
              e.stopPropagation();
              return false;
              alert('Error');
            }
          }

          return true;
        });
      }
    }

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();