'use strict';

loadjs.ready(['vue'], {
  success: function success() {
    if (!document.querySelectorAll('.step-by-step-scope')) {
      return false;
    }
    // Define a new component called step-by-step
    Vue.component('step-by-step', {
      props: {
        type: {
          required: true
        },
        items: {
          required: true
        }
      },

      data: function data() {
        return {
          step: 1
        };
      },

      created: function created() {
        setTimeout(function () {
          AOS.init();
        }, 255);
      },

      mounted: function mounted() {
        console.log(this.items);
      },

      methods: {
        changeStep: function methodName(step) {
          this.step = step;
        }
      },

      watch: {
        dataname: function dataname(_dataname) {
          // ...
        }
      },

      template: '\n        <div class="steps-list-container" data-aos="fade-up" data-aos-delay="300">\n          <ul class="steps-list" v-if="type == \'ul\'">\n            <li\n              :key="index"\n              class="steps-list-items"\n              :class="step >= item.id ? \'is-active\' : \'\'"\n              v-for="(item, index) in items"\n              v-text="item.heading"\n              @click="changeStep(item.id)">\n            </li>\n          </ul>\n          <ol class="steps-list" v-else>\n            <li\n              :key="index"\n              class="steps-list-items"\n              :class="step == item.id ? \'is-active\' : \'\'"\n              v-for="(item, index) in items"\n              v-text="item.id"\n              @click="changeStep(item.id)"></li>\n          </ol>\n          <transition name="fade-box" v-for="(item, index) in items">\n            <div class="steps-details" v-cloak v-if="step == item.id" data-aos="fade-up" data-aos-delay="500" >\n              <div class="steps-header small-title" v-if="item.heading !== null">\n                <h4 class="steps-heading" v-text="item.heading"></h4>\n                <div class="icon-wrapper">\n                  <img\n                  :src="item.icone"\n                  v-if="type == \'ul\' || item.icone">\n                </div>\n              </div>\n              <div class="steps-text small-text" v-html="item.text">\n              </div>\n              <a :href="item.buttonUrl" class="site-btn is-alternate problematic-btn" v-text="item.buttonText" v-if="item.buttonText"></a>\n            </div>\n          </transition>\n        </div>\n      '
    });
    // console.log(blockId);

    // const

    document.querySelectorAll('.step-by-step-scope').forEach(function (step, i) {
      var el = '.' + step.getAttribute('data-vue-block-id');
      if (el) {
        return new Vue({ el: el });
      } else {
        return;
      }
    });

    // return init;
  }
});