'use strict';

// Globals and dependencies
/* global Vue */
/* global loadjs */

loadjs.ready(['vue', 'axios'], {
  success: function success() {
    if (!document.querySelector('#scroll-link-scope')) {
      return false;
    }
    // Define a new component called ScrollLink
    Vue.component('scroll-link', {
      props: ['href'],

      methods: {
        scroll: function scroll() {
          document.querySelector(this.href).scrollIntoView({ behavior: 'smooth' });
        }
      },
      template: '\n        <a :href="href" @click.prevent="scroll">\n          <slot></slot>\n        </a>\n      '
    });

    var init = new Vue({ el: '#scroll-link-scope' });

    return init;
  }
});