// window.site = (window.site || {});
//
// /**
//  * Init Mobile Menu related scripts
//  * @class Site
//  * @static
//  */
// site.StepByStep = (function StepByStep() {
//
//   'use strict';
//
//   /**
//    * jQuery elements
//    * @private
//    */
//
//   /**
//    * Has the class been initialized?
//    * @private
//    */
//   var inited = false;
//
//   /**
//    * Initializes the class.
//    * @public
//    */
//   var init = function () {
//
//     // Abort if already initialized
//     if (inited) {
//       return false;
//     }
//
//     inited = true;
//
//     $listItems = document.querySelectorAll('[data-step-list-item]');
//     $elements = document.querySelectorAll('[data-step-item]');
//
//     console.log($listItems);
//
//     $elements.forEach(($element, i) => {
//       if ($element.getAttribute("data-step-is-active") == "true") {
//         $element.classList.add("is-active");
//         $listItems[i].classList.add("is-active");
//       }
//     });
//
//     // document.addEventListener("click", (e) => {
//     //   console.log(e.target);
//     // });
//     console.log($listItems[0]);
//     $listItems[0].addEventListener("click", () => {
//       console.log('lol');
//     });
//
//     $listItems.forEach(($listItem) => {
//       // console.log($listItem);
//       $listItem.addEventListener("click", (e) => {
//         console.log(e.target);
//         console.log('click');
//       });
//     });
//
//     return true;
//
//   };
//   // Expose public methods & properties
//   return {
//     init: init,
//   };
//
// }());
"use strict";