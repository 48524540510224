'use strict';

// Globals and dependencies
/* global Vue */
/* global loadjs */
/* global inViewport */

loadjs.ready(['vue', 'axios'], {
  success: function success() {
    if (!document.querySelector('#count-scope')) {
      return false;
    }
    // Define a new component called count
    Vue.component('count', {
      props: ['to'],

      data: function data() {
        return {
          count: 0,
          interval: null
        };
      },

      computed: {
        increment: function increment() {
          return Math.ceil(this.to / 20);
        }
      },

      mounted: function mounted() {
        var _this = this;

        inViewport(this.$el, function () {
          _this.interval = setInterval(_this.tick, 20);
        });
      },

      methods: {
        tick: function tick() {
          if (this.count + this.increment >= this.to) {
            this.count = this.to;
          }

          if (this.count < this.to) {
            this.count = this.count + this.increment;
          } else {
            clearInterval(this.interval);
          }
        }
      },
      template: '\n        <span v-text="count"></span>\n      '
    });

    var init = new Vue({ el: '#count-scope' });

    return init;
  }
});